import { memo, useState } from 'react';
import {
  AxisConfigDict,
  ChannelDataDictPowerLog,
  ChannelDataPowerLog,
  ChannelOptionDictPowerLog,
  ChannelOptionPowerLog,
  DataGraph,
  DataGraphYConfig,
  YDataOptionDictPowerLog,
  YDataOptionPowerLog
} from '@/interfaces/graph';
import LineGraphSimple from '../../LineGraphSimple';
import { findMax, findMin } from '@/utils';

export interface IBuildGraph {
  heightEachEl: number;
  channelOptionDict: ChannelOptionDictPowerLog;
  channelDataDict: ChannelDataDictPowerLog;
  yDataOptionDict: YDataOptionDictPowerLog;
  axisConfigDict: AxisConfigDict;
  showTooltip: boolean;
  onZoom: any;
}

const X_LABELS_HEIGTH = 80;

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    // const table = document.createElement('table');
    // table.style.margin = '0px';

    // tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

function BuildGraph({
  heightEachEl,
  channelOptionDict,
  channelDataDict,
  yDataOptionDict,
  axisConfigDict,
  showTooltip,
  onZoom
}: IBuildGraph) {
  const [data, setData] = useState<DataGraph>();
  let lastIndex = -1;
  let totalVisibleGraphics = 0;

  console.log('channelDataDict', channelDataDict);
  Object.values(channelOptionDict).forEach(
    (channelOption: ChannelOptionPowerLog, index: number) => {
      if (channelOption.isShow) {
        lastIndex = index;
        totalVisibleGraphics++;
      }
    }
  );

  console.log({
    channelOptionDict,
    channelDataDict,
    yDataOptionDict,
    axisConfigDict,
    showTooltip
  });
  const generateYScale = (dataset: number[]) => {
    if (!dataset)
      return {
        min: 0,
        max: 'original'
      };
    const max = findMax(dataset);
    const min = findMin(dataset);
    return {
      min,
      max
    };
  };

  return (
    <div className="flex h-full absolute top-0 left-0 w-full flex-col">
      {Object.values(channelOptionDict).map(
        (channelOption: ChannelOptionPowerLog, index: number) => {
          if (!channelOption.isShow) {
            return '';
          }
          const channelData: ChannelDataPowerLog =
            channelDataDict[channelOption.key];
          const yList: any[] = [];
          const yListTypes: any[] = [];
          const yConfigList: DataGraphYConfig[] = [];
          Object.values(yDataOptionDict).forEach(
            (yDataOption: YDataOptionPowerLog) => {
              const yData = channelData.y[yDataOption.key];
              yList.push(yData);
              const y_type_point = channelData.y_type_point || [];
              yListTypes.push(y_type_point[yDataOption.key]);
              yConfigList.push({
                color: yDataOption.color,
                label: yDataOption.label,
                isShow: yDataOption.isShow,
                groupAxis: yDataOption.axisGroup
              });
            }
          );
          console.log('yList:', yList);
          const data: DataGraph = {
            x: channelData.x,
            y: yList,
            // y_type_point: yListTypes,
            x_labels: channelData.x_label,
            scales: {
              y: generateYScale(yList[0])
            },
            x_config: {
              showAxis: lastIndex == index
            },
            // yScale: findMax(yList),
            y_config: yConfigList
          };
          const heigthLabel =
            lastIndex == index
              ? `+ ${
                  X_LABELS_HEIGTH - X_LABELS_HEIGTH / totalVisibleGraphics
                }px`
              : `- ${X_LABELS_HEIGTH / totalVisibleGraphics}px`;
              
          return channelDataDict.hasOwnProperty(channelOption.key) ? (
            <div
              key={index}
              style={{
                height: `calc(${heightEachEl}% ${heigthLabel})`,
                width: '100%'
              }} // Dynamic heigth
            >
              {lastIndex == index}
              {data && (
                <LineGraphSimple
                  _data={data}
                  backgroundRanges={
                    channelDataDict[channelOption.key].backgroundRanges || []
                  }
                  axisDict={axisConfigDict as AxisConfigDict}
                  // labels={channelDataDict[channeConfig.key].x_label} // Labels for X axis
                  showTooltip={showTooltip} // Flag show/hide tooltip
                  // showDatasetMap={yDataOptionDict} // Flag show/hide de lines
                  // data={{
                  //   x: JSON.parse((channelDataDict as any)[channeConfig.key]).x,
                  //   y: JSON.parse((channelDataDict as any)[channeConfig.key]).y,
                  //   y_config: []
                  // }} // Enviar data del eje X y Y (Original)
                  // colors={{
                  //   default: '#000000'
                  // }} // Enviar diccionario de colores
                  onZoom={(x1: any, x2: any) => {
                    onZoom(x1, x2, channelDataDict[channelOption.key]);
                  }} // Evento de Zoom
                  title={channelOption.label} // Titulo del grafico
                />
              )}
            </div>
          ) : (
            ''
          );
        }
      )}
    </div>
  );
}

export default memo(BuildGraph);
