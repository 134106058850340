import { useParams } from 'react-router';
import {
  YConfig,
  ChannelConfig,
  ChannelDataPowerLog,
  AxisConfigDict,
  PowerLogConfig
} from '@/interfaces/graph';
import environment from '@/config/env';
import PowerLogGraph from '../PowerLogGraph';

const graphMetadata: ChannelConfig[] = [
  {
    key: 'aa',
    label: 'A[A]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_A(A)_v2`
  },
  {
    key: 'ba',
    label: 'B[A]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_B(A)_v2`
  },
  {
    key: 'ca',
    label: 'C[A]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_C(A)_v2`
  },
  {
    key: 'na',
    label: 'N[A]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_N(A)_v2`
  },
  {
    key: 'av',
    label: 'A[V]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_A(V)_v2`
  },
  {
    key: 'bv',
    label: 'B[V]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_B(V)_v2`
  },
  {
    key: 'cv',
    label: 'C[V]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_C(V)_v2`
  }
  // {
  //   "key": "gv",
  //   "label": "G(V)",
  //   "url": "https://apidev.electripuremeter.com/get_chart1_G(V)_v2"
  // }
];

const yConfigs: YConfig[] = [
  // {
  //   key: 'max',
  //   color: '#fc0303',
  //   labelList: ['Maximum'],
  //   label: 'Maximum',
  //   labelShort: 'Maximum',
  //   groupList: ['Display'],
  //   axisGroup: 'y'
  // },
  {
    key: 'average',
    color: '#000000',
    labelList: ['Average'],
    label: 'Average',
    labelShort: 'Average',
    groupList: ['Display'],
    axisGroup: 'y'
  }
  // {
  //   key: 'min',
  //   color: '#00ff3c',
  //   labelList: ['Minimum'],
  //   label: 'Minimum',
  //   labelShort: 'Minimum',
  //   groupList: ['Display'],
  //   axisGroup: 'y'
  // }
];

function toData(channel: ChannelConfig, result: any): ChannelDataPowerLog {
  return {
    x: result['TS_data'],
    x_label: result['TS_data_label'],

    timestamp: result['TS_unix'],
    y: {
      max: result['MAX_data'],
      min: result['MIN_data'],
      average: result['AVERAGE_data']
    },
    backgroundRanges: [
      {
        color: 'rgba(200, 0, 0, 0.5)',
        from: 100,
        to: 200
      }
    ],
    y_type_point: {
      max: result['MAX_data'].map(() => {
        return 'Normal';
      }),
      min: result['MIN_data'].map(() => {
        return 'Normal';
      }),
      average: result['AVERAGE_data'].map(() => {
        const status = ['Error', 'Warning', 'Normal'];
        return status[Math.floor(Math.random() * 3)];
      })
    }
  };
}

const axisConfigDict: AxisConfigDict = {
  y: {
    position: 'left',
    type: 'linear'
  }
};

const powerLogConfig: PowerLogConfig = {
  threshold: 0
};

function VoltageCurrentGraph({ defaultMeterId }: { defaultMeterId?: number }) {
  let { meterId } = useParams();
  let deviceId = defaultMeterId ?? parseInt(meterId!);
  return (
    <PowerLogGraph
      config={powerLogConfig}
      yConfigList={yConfigs}
      toData={toData}
      deviceId={deviceId}
      axisConfigDict={axisConfigDict}
      channelConfigList={graphMetadata}
    />
  );
}

export default VoltageCurrentGraph;
