import { useState } from 'react';
import { INPUT_CONTROL_STATE, TYPE_SPACE } from '@/config/enum';
import { ConfirmCodeDataForm } from '@/interfaces/form';
import { InputControl } from '@/interfaces/form-control';
import { validateCodeControl } from '@/libs/form-validation';
import { ButtonLink, ButtonPrimary } from '../../FormInput/Button';
import InputText from '../../FormInput/InputText';
import Title from '../../FormInput/Title';
import Space from '../../Space';

type ConfirmCodeFormProps = {
  onSubmit: (data: ConfirmCodeDataForm) => void;
  resendCode: () => void;
};
function ConfirmCodeForm({ onSubmit, resendCode }: ConfirmCodeFormProps) {
  const [codeControl, setCodeControl] = useState({
    value: '',
    message: '',
    state: -1
  });

  function submit() {
    if (codeControl.state == INPUT_CONTROL_STATE.OK) {
      onSubmit({
        code: codeControl.value
      });
    }
  }

  return (
    <div className="w-full bg-color-white p-[10px]">
      <Title title="Enter your authorization code"></Title>
      <p>How would you like to recieve your autherization code?</p>
      <br />
      <InputText
        name="code"
        placeholder="000000"
        label="6 digital code"
        onChange={(value: string) => {
          const newCodeControl: InputControl = validateCodeControl(value);
          setCodeControl(newCodeControl);
        }}
        state={codeControl.state}
        message={codeControl.message}
      />
      <Space type={TYPE_SPACE.INPUT_DISTANCE} />
      <div className={'justify-start items-center flex'}>
        <ButtonLink onClick={resendCode}>Resend code</ButtonLink>
      </div>
      <Space type={TYPE_SPACE.INPUT_DISTANCE} />
      <ButtonPrimary onClick={submit}>Log in</ButtonPrimary>
    </div>
  );
}

export default ConfirmCodeForm;
