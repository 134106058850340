import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SendImage } from '@/actions/electripure';
import { INPUT_CONTROL_STATE, TASK_STATE, TYPE_SPACE } from '@/config/enum';
import { TaskEntity } from '@/interfaces/entities';
import { ElectripureState } from '@/interfaces/reducers';
import { ButtonPrimary } from '../../FormInput/Button';
import InputPhoto from '../../FormInput/InputPhoto';
import InputSelect from '../../FormInput/InputSelect';
import InputText from '../../FormInput/InputText';
import Title from '../../FormInput/Title';
import Space from '../../Space';
import environment from '@/config/env';
import {
  validateCellphoneControl,
  validateEmailControl,
  validateNameControl,
  validateRequiredControl
} from '@/libs/form-validation';
import { InputControl } from '@/interfaces/form-control';
import formatter from '@/libs/formatter';
import ConfirmationModal from '../../ConfirmationModal';

export interface ISiteForm {
  id: number;
  logo_id: number;
  schematic_id: number;
  manager_phone: string;
  manager_fullname: string;
  manager_email: string;
  name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  payment: string;
  schematic_url: string;
  logo_url: string;
}

const STATE_OPTIONS = environment.STATES.map((value, index) => ({
  id: index + 1,
  value: value
}));
const SCHEDULE_OPTIONS = environment.RATE_SCHEDULE;

function SiteForm({
  title = 'Create Site',
  button = 'Create',
  onSubmit,
  site,
  onDelete,
  type
}: {
  title?: string;
  button?: string;
  onSubmit: (data: Partial<ISiteForm>) => void;
  site: Partial<ISiteForm>;
  onDelete?: () => Promise<void>;
  type?: 'create' | 'update';
}) {
  const dispatch = useDispatch();
  const [showConfirmationPopup, setShowConfirmationPopup] =
    useState<boolean>(false);
  const [logoId, setLogoId] = useState(site.logo_id);
  const [cellphone, setCellphone] = useState(site.manager_phone ?? '');
  const [schematicId, setSchematicId] = useState(site.schematic_id);
  const uploadLogoTask: TaskEntity =
    JSON.parse(useSelector((state: ElectripureState) => state.tasks))[
      'UPLOAD_SITE_LOGO'
    ] ?? {};
  const uploadSchematicTask: TaskEntity =
    JSON.parse(useSelector((state: ElectripureState) => state.tasks))[
      'UPLOAD_SITE_SCHEMATIC'
    ] ?? {};

  useEffect(() => {
    if (uploadLogoTask.state == TASK_STATE.COMPLETED) {
      setLogoId(uploadLogoTask.result);
    }
  }, [uploadLogoTask.state]);

  useEffect(() => {
    if (uploadSchematicTask.state == TASK_STATE.COMPLETED) {
      setSchematicId(uploadSchematicTask.result);
    }
  }, [uploadSchematicTask.state]);

  const [managerFullNameControl, setManagerFullNameControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.manager_fullname ?? '',
    message: ''
  });

  const [managerEmailControl, setManagerEmailControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.manager_email ?? '',
    message: ''
  });

  const [managerPhoneControl, setManagerPhoneControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.manager_phone ?? '',
    message: ''
  });

  const [nameControl, setNameControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.name ?? '',
    message: ''
  });

  const [addressControl, setAddressControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.address ?? '',
    message: ''
  });

  const [address2Control, setAddress2Control] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.address2 ?? '',
    message: ''
  });

  const [cityControl, setCityControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.city ?? '',
    message: ''
  });

  const [stateControl, setStateControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.state ?? '',
    message: ''
  });

  const [zipControl, setZipControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.zip ?? '',
    message: ''
  });

  const [rateControl, setRateControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.payment ?? '',
    message: ''
  });

  const [schematicControl, setSchematicControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.schematic_url ?? '',
    message: ''
  });

  const [logoControl, setLogoControl] = useState({
    state: INPUT_CONTROL_STATE.DEFAULT,
    value: site.logo_url,
    message: ''
  });

  function uploadLogo({ base64, size }: { base64: string; size: number }) {
    if (size > 500000) {
      setLogoControl({
        message: 'Image max size is 500kb.',
        state: INPUT_CONTROL_STATE.ERROR,
        value: ''
      });
      return;
    }
    setLogoControl({
      state: INPUT_CONTROL_STATE.DEFAULT,
      value: base64,
      message: ''
    });
    dispatch(
      SendImage({
        base64: base64.split(',')[1],
        taskKey: 'UPLOAD_SITE_LOGO'
      })
    );
  }

  function uploadSchematic({ base64, size }: { base64: string; size: number }) {
    if (size > 500000) {
      setSchematicControl({
        message: 'Image max size is 500kb.',
        state: INPUT_CONTROL_STATE.ERROR,
        value: ''
      });
      return;
    }
    setSchematicControl({
      state: INPUT_CONTROL_STATE.OK,
      value: base64,
      message: ''
    });
    dispatch(
      SendImage({
        base64: base64.split(',')[1],
        taskKey: 'UPLOAD_SITE_SCHEMATIC'
      })
    );
  }

  function submit() {
    if (
      nameControl.state == INPUT_CONTROL_STATE.OK &&
      addressControl.state == INPUT_CONTROL_STATE.OK &&
      cityControl.state == INPUT_CONTROL_STATE.OK &&
      stateControl.state == INPUT_CONTROL_STATE.OK &&
      managerEmailControl.state == INPUT_CONTROL_STATE.OK &&
      managerFullNameControl.state == INPUT_CONTROL_STATE.OK &&
      managerPhoneControl.state == INPUT_CONTROL_STATE.OK &&
      zipControl.state == INPUT_CONTROL_STATE.OK
    ) {
      onSubmit({
        schematic_url: '',
        logo_url: '',
        id: site.id,
        manager_email: managerEmailControl.value,
        manager_fullname: managerFullNameControl.value,
        manager_phone: managerPhoneControl.value,
        schematic_id: schematicId,
        name: nameControl.value,
        address: addressControl.value,
        address2: address2Control.value,
        city: cityControl.value,
        state: stateControl.value,
        zip: zipControl.value,
        logo_id: logoId,
        payment: rateControl.value
      });
    } else {
      // Validate required fields
      setNameControl(validateRequiredControl(nameControl));
      setAddressControl(validateRequiredControl(addressControl));
      setCityControl(validateRequiredControl(cityControl));
      setStateControl(validateRequiredControl(stateControl));
      setZipControl(validateRequiredControl(zipControl));
      setManagerFullNameControl(
        validateRequiredControl(managerFullNameControl)
      );
      setManagerEmailControl(validateRequiredControl(managerEmailControl));
      setManagerPhoneControl(validateRequiredControl(managerPhoneControl));
    }
  }

  const handleDelete = async () => {
    await onDelete!();
    setShowConfirmationPopup(false);
  };

  const handleShowModal = () => {
    setShowConfirmationPopup(true);
  };

  return (
    <>
      <div className="w-full bg-color-white p-[10px]">
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <div
          className="mx-auto w-full max-w-[650px]"
          style={{ textAlign: 'center' }}>
          <Title title={title} />
        </div>
        <div className="w-full flex">
          <div className="w-[100px] p-[5px] h-[100px]">
            <InputPhoto
              name="siteLogo"
              placeholder=""
              src={logoControl.value}
              onChange={uploadLogo}
              state={logoControl.state}
              message={logoControl.message}
            />
          </div>
          <div className="w-full pl-[20px]">
            <InputText
              name="name"
              label="Site Name"
              placeholder="Site name"
              state={nameControl.state}
              message={nameControl.message}
              defaultValue={site.name}
              onChange={(value: string) => {
                setNameControl({
                  state:
                    value == ''
                      ? INPUT_CONTROL_STATE.DEFAULT
                      : INPUT_CONTROL_STATE.OK,
                  message: '',
                  value: value
                });
              }}
            />
            <Space type={TYPE_SPACE.INPUT_DISTANCE} />
            <div>
              <InputText
                name="address"
                label="Address"
                placeholder="12345 Street Address"
                state={addressControl.state}
                defaultValue={site.address}
                message={addressControl.message}
                onChange={(value: string) => {
                  setAddressControl({
                    state:
                      value == ''
                        ? INPUT_CONTROL_STATE.DEFAULT
                        : INPUT_CONTROL_STATE.OK,
                    message: '',
                    value: value
                  });
                }}
              />
              <Space type={TYPE_SPACE.INPUT_DISTANCE} />
              <InputText
                name="address2"
                label="Address 2 optional"
                placeholder="Suite 890"
                defaultValue={site.address2}
                state={address2Control.state}
                message={address2Control.message}
                onChange={(value: string) => {
                  setAddress2Control({
                    state:
                      value == ''
                        ? INPUT_CONTROL_STATE.DEFAULT
                        : INPUT_CONTROL_STATE.OK,
                    message: '',
                    value: value
                  });
                }}
              />
              <Space type={TYPE_SPACE.INPUT_DISTANCE} />
              <div className="flex">
                <InputText
                  name="city"
                  label="City"
                  placeholder="City"
                  defaultValue={site.city}
                  state={cityControl.state}
                  message={cityControl.message}
                  onChange={(value: string) => {
                    setCityControl({
                      state:
                        value == ''
                          ? INPUT_CONTROL_STATE.DEFAULT
                          : INPUT_CONTROL_STATE.OK,
                      message: '',
                      value: value
                    });
                  }}
                />
                <Space classes="w-[60px]" />
                <InputSelect
                  name="state"
                  label="State"
                  options={STATE_OPTIONS}
                  defaultSelect={site.state}
                  placeholder="Select State"
                  state={stateControl.state}
                  message={stateControl.message}
                  onChange={(select: { value: any; id: any }) => {
                    setStateControl({
                      state: INPUT_CONTROL_STATE.OK,
                      message: '',
                      value: select.value
                    });
                  }}
                />
                <Space classes="w-[60px]" />
                <InputText
                  name="zip"
                  label="Zipcode"
                  placeholder="Zip"
                  defaultValue={site.zip}
                  state={zipControl.state}
                  message={zipControl.message}
                  onChange={(value: string) => {
                    setZipControl({
                      state:
                        value == ''
                          ? INPUT_CONTROL_STATE.DEFAULT
                          : INPUT_CONTROL_STATE.OK,
                      message: '',
                      value: value
                    });
                  }}
                />
              </div>
              <Space type={TYPE_SPACE.INPUT_DISTANCE} />
            </div>
            <InputSelect
              name="rate"
              label="Rate schedule"
              options={SCHEDULE_OPTIONS}
              placeholder="Select payment schedule"
              state={rateControl.state}
              defaultSelect={site.payment}
              message={rateControl.message}
              onChange={(select: { value: any; id: any }) => {
                setRateControl({
                  state: INPUT_CONTROL_STATE.OK,
                  message: '',
                  value: select.value
                });
              }}
            />
            <Space type={TYPE_SPACE.INPUT_DISTANCE} />
            <InputText
              name="fullname"
              label="Manager Full Name"
              placeholder="John Doe"
              value={managerFullNameControl.value}
              state={managerFullNameControl.state}
              message={managerFullNameControl.message}
              onChange={(value: string) => {
                const newManagerFullNameControl: InputControl =
                  validateNameControl(value);
                setManagerFullNameControl(newManagerFullNameControl);
              }}
            />
            <Space type={TYPE_SPACE.INPUT_DISTANCE} />
            <InputText
              name="email"
              label="Manager Email"
              placeholder="email@company.com"
              value={managerEmailControl.value}
              state={managerEmailControl.state}
              message={managerEmailControl.message}
              onChange={(value: string) => {
                const newManagerEmailControl: InputControl =
                  validateEmailControl(value);
                setManagerEmailControl(newManagerEmailControl);
              }}
            />
            <Space type={TYPE_SPACE.INPUT_DISTANCE} />
            <InputText
              name="cellphone"
              label="Manager Cellphone"
              value={cellphone}
              placeholder="(***) *** - ****"
              state={managerPhoneControl.state}
              message={managerPhoneControl.message}
              onChange={(value: string) => {
                const cellphone = value
                  .replace('-', '')
                  .replace('(', '')
                  .replace(')', '')
                  .replace(' ', '');

                const newManagerCellphoneControl: InputControl =
                  validateCellphoneControl(cellphone);
                setManagerPhoneControl(newManagerCellphoneControl);

                if (
                  newManagerCellphoneControl.state == INPUT_CONTROL_STATE.OK
                ) {
                  setCellphone(formatter.toCellphoneFormat(cellphone));
                } else {
                  setCellphone(value);
                }

                setManagerPhoneControl(newManagerCellphoneControl);
              }}
            />
          </div>
        </div>
        <Space type={TYPE_SPACE.INPUT_DISTANCE} />
        <div className="w-full h-[150px]">
          <InputPhoto
            name="schematic"
            placeholder="Add site schematic"
            src={schematicControl.value}
            onChange={uploadSchematic}
            state={schematicControl.state}
            message={schematicControl.message}
          />
        </div>
        <div className="flex gap-x-12 mt-12">
          <ButtonPrimary onClick={submit}>{button}</ButtonPrimary>
          {type == 'update' && (
            <button
              className="bg-red-800 w-full text-white"
              onClick={handleShowModal}>
              Delete
            </button>
          )}
        </div>
      </div>
      <ConfirmationModal
        open={showConfirmationPopup}
        onSuccess={handleDelete}
        onCancel={() => setShowConfirmationPopup(false)}
      />
    </>
  );
}

export default SiteForm;
