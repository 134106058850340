import { ElectripureState } from '@/interfaces/reducers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router';

function VoltageCurrentPage() {
  const { meterId } = useParams();

  const companies = JSON.parse(
    useSelector((state: ElectripureState) => state.companies)
  );

  console.log({ meterId });
  const companyObject = useMemo(() => {
    const obj: any = {};
    companies?.map((company) => {
      const result = company?.list_sites.map((site) =>
        site?.mdp_list.map((mdp) => {
          if (mdp.meter_id == meterId) {
            obj.meter = mdp;
            obj.company = company;
          }
        })
      );
    });
    return obj;
  }, [meterId]);

  console.log({ companyObject });
  return (
    <>
      <div className="h-[30px] flex items-center gap-x-2">
        <h3 className="f-bold text-lg text-blue-600	">Voltage & Current</h3>
        <span>
          {companyObject.company.company_name},{' '}
          {companyObject.meter.name}
        </span>
      </div>
      <div
        style={{ height: 'calc(100% - 30px)' }}
        className="w-full rounded border-color-secondary border bg-white p-[30px]">
        <Outlet />
      </div>
    </>
  );
}

export default VoltageCurrentPage;
