import { useParams } from 'react-router';
import {
  YConfig,
  ChannelConfig,
  ChannelDataPowerLog,
  PowerLogConfig,
  AxisConfigDict
} from '@/interfaces/graph';
import environment from '@/config/env';
import PowerLogGraph from '../PowerLogGraph';

const graphMetadata: ChannelConfig[] = [
  {
    key: 'a1',
    label: 'A1[%]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_A1_v2`
  },
  {
    key: 'a2',
    label: 'A2[%]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_A2_v2`
  },
  {
    key: 'a3',
    label: 'A3[%]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_A3_v2`
  },
  {
    key: 'an',
    label: 'AN[%]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_AN_v2`
  },
  {
    key: 'v1',
    label: 'V1[%]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_V1_v2`
  },
  {
    key: 'v2',
    label: 'V2[%]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_V2_v2`
  },
  {
    key: 'v3',
    label: 'V3[%]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_V3_v2`
  },
  {
    key: 'vn',
    label: 'VN[%]',
    url: `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_VN_v2`
  }
];

function toData(channel: ChannelConfig, result: any): ChannelDataPowerLog {
  return {
    x: result['TS_data'],
    x_label: result['TS_data_label'],
    timestamp: result['TS_unix'],
    y: {
      h1: result['H1'],
      h3: result['H3'],
      h5: result['H5'],
      h7: result['H7'],
      h9: result['H9']
    },
    backgroundRanges: [],
    y_type_point: {
      h1: result['H1'].map(() => 'Normal'),
      h3: result['H3'].map(() => 'Normal'),
      h5: result['H5'].map(() => 'Normal'),
      h7: result['H7'].map(() => 'Normal'),
      h9: result['H9'].map(() => 'Normal')
    }
  };
}
// SECTION Mapper for config for each y dataset
// NOTE Power log graph will group all the declared groups and also the labels declared for each group (respecting the index of label and group). For a group to be displayed its labels must be active.

const yConfigs: YConfig[] = [
  {
    key: 'h1',
    color: '#00AEE8',
    label: 'H1',
    labelShort: 'H1',
    labelList: ['H1'],
    axisGroup: 'y',
    groupList: ['Display']
  },
  {
    key: 'h3',
    color: '#55BA47',
    label: 'H3',
    labelShort: 'H3',
    labelList: ['H3'],
    axisGroup: 'y',
    groupList: ['Display']
  },
  {
    key: 'h5',
    color: '#263B92',
    label: 'H5',
    labelShort: 'H5',
    labelList: ['H5'],
    axisGroup: 'y',
    groupList: ['Display']
  },
  {
    key: 'h7',
    color: '#ed4278',
    label: 'H7',
    labelShort: 'H7',
    labelList: ['H7'],
    axisGroup: 'y',
    groupList: ['Display']
  },
  {
    key: 'h9',
    color: '#000000',
    label: 'H9',
    labelShort: 'H9',
    labelList: ['H9'],
    axisGroup: 'y',
    groupList: ['Display']
  }
];

const axisConfigDict: AxisConfigDict = {
  y: {
    position: 'left',
    type: 'linear'
  }
};

const powerLogConfig: PowerLogConfig = {
  threshold: 0
};

function HarmonicGraph({ defaultMeterId }: { defaultMeterId?: number }) {
  let { meterId } = useParams();
  let deviceId = defaultMeterId ?? parseInt(meterId!);
  return (
    <PowerLogGraph
      config={powerLogConfig}
      yConfigList={yConfigs}
      toData={toData}
      deviceId={deviceId}
      axisConfigDict={axisConfigDict}
      channelConfigList={graphMetadata}
    />
  );
}

export default HarmonicGraph;
