import { IconSort } from '@/assets/svg';
import { ReactNode, useEffect, useRef, useState } from 'react';

type DataTableProps<TableHeaders, TableBody> = {
  headers: TableHeaders;
  data: TableBody;
};

export type Header = {
  key: string;
  label: ReactNode;
  sorted?: boolean;
};

export const DataTable = <
  TableHeaders extends Header[],
  TableBody extends Array<Record<string, any>>
>({
  headers,
  data
}: DataTableProps<TableHeaders, TableBody>) => {
  const [sortedData, setSortedData] = useState<TableBody>(data);
  const sortedKey = useRef<string>();
  const sortDirection = useRef<string>();

  console.log({ sortedData });

  useEffect(() => {
    if (data) setSortedData(data);
  }, [data]);

  const sortData = (key: string) => {
    const direction =
      sortDirection.current === 'asc' && sortedKey.current === key
        ? 'desc'
        : 'asc';

    const sorted = [...sortedData].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });

    setSortedData(sorted as TableBody);
    sortDirection.current = direction;
    sortedKey.current = key;
  };

  return (
    <table className="w-full">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index} className="font-semibold max-w-32 py-2">
              <span className="flex items-center gap-x-3 justify-center">
                {header.label}
                {header.sorted && (
                  <span onClick={() => sortData(header.key)}>
                    <IconSort />
                  </span>
                )}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedData.map((d, index) => (
          <tr className="odd:bg-gray-100-electripure">
            {headers.map((h, colIndex) => (
              <td
                className="border-none text-center py-2"
                key={`row-${index}_col-${colIndex}`}>
                {d[h.key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
