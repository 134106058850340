import { VERIFICATION_CHANNEL } from '@/config/enum';
import { SelectVerifyMethodDataForm } from '@/interfaces/form';
import { ButtonSecondary } from '../../FormInput/Button';
import Title from '../../FormInput/Title';
import { IconEmail } from '@/assets/svg';

type SelectVerifyMethodDataFormProps = {
  onSubmit: (data: SelectVerifyMethodDataForm) => void;
};
function SelectVerifyMethodForm({ onSubmit }: SelectVerifyMethodDataFormProps) {
  return (
    <div className="w-full bg-color-white p-[10px]">
      <Title title="Two-step verification"></Title>
      <div className="w-full flex justify-center">
        <ButtonSecondary
          classes="h-[101px] w-[100px]"
          onClick={() => {
            onSubmit({ channel: VERIFICATION_CHANNEL.EMAIL });
          }}>
          <div className="flex flex-col justify-center items-center">
            <IconEmail />
            <p className="color-black-dark f-medium">Email</p>
          </div>
        </ButtonSecondary>
      </div>
    </div>
  );
}

export default SelectVerifyMethodForm;
