import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendAddCompany } from '../actions/electripure';
import { ElectripureState } from './../interfaces/reducers';
import BasicCompanyInformationForm from '../components/Form/BasicCompanyInformationForm';
import {
  BasicCompanyInformationDataForm,
  MainPointContactDataForm,
  SiteManagerDataForm,
  CreateMDPDataForm,
  SiteDetailDataForm
} from '../interfaces/form';
import MainPointContactForm from '../components/Form/MainPointContactForm';
import { ModalMiddle } from './../components/Modal';
import SiteManagerForm from './../components/Form/SiteManagerForm';
import SiteDetailForm from './../components/Form/SiteDetailForm';
import CreateMDPForm from './../components/Form/CreateMDPForm';
import { ButtonSecondary } from '../components/FormInput/Button';
import DataTableCompanies from '../components/DataTables/DataTableCompanies';
import { RoleGuard } from '../guards/RoleGuard';
import { PERMISSIONS } from '../utils/roles';
import IconAdd from '../assets/svg/add.svg';

function CompanyListPage() {
  const [isShowModal, setShowModal] = useState(false);
  const [schematicImg, setSchematicImg] = useState<null | string>(null);
  const [siteDefaultDataForm, setSiteDefaultDataForm] = useState(`{
        "name": "",
        "address": "",
        "address2": "",
        "city": "",
        "state": "",
        "zip": "",
        "logo": "",
        "rate": "",
        "schematic": ""
    }`);
  const toastMessage = useSelector(
    (state: ElectripureState) => state.toastMessage
  );
  const dispatch = useDispatch();

  const [newCompanyRaw, setNewCompany] = useState(
    `{"id_user": ${localStorage.getItem('user_id')}}`
  );
  const [stepCreateCompany, setStepCreateCompany] = useState(1);
  const newCompany = JSON.parse(newCompanyRaw);

  function submitBasicCompanyInformationForm(
    data: BasicCompanyInformationDataForm
  ) {
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        basicInformation: {
          companyName: data.company,
          address: data.address,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip,
          hasMeter: data.hasMeter,
          imgId: data.logo
        }
      })
    );
    setSiteDefaultDataForm(
      JSON.stringify({
        address: data.address,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip
      })
    );
    setStepCreateCompany(2);
  }

  function submitMainPointContactForm(data: MainPointContactDataForm) {
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        mainPointContact: {
          fullName: data.fullname,
          email: data.email,
          cellPhone: data.cellphone
        }
      })
    );
    setStepCreateCompany(3);
  }

  function submitSiteManagerForm(data: SiteManagerDataForm) {
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        siteManager: {
          fullName: data.fullname,
          email: data.email,
          cellPhone: data.cellphone
        }
      })
    );
    setStepCreateCompany(4);
  }

  function submitSiteDetailForm(data: SiteDetailDataForm) {
    setSchematicImg(data.schematicBase64);
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        siteDetails: {
          siteName: data.name,
          address: data.address,
          address2: data.address2,
          city: data.city,
          state: data.state,
          zip: data.zip,
          paymentSchedule: data.rate,
          imgId: data.logo,
          imgSchematic: data.schematic
        }
      })
    );
    setStepCreateCompany(5);
  }

  function submitCreateMDPForm(data: CreateMDPDataForm[]) {
    setNewCompany(
      JSON.stringify({
        ...newCompany,
        MDP: data.map((mdpData: CreateMDPDataForm) => {
          return {
            siteName: mdpData.name,
            meterID: mdpData.meterId,
            applianceID: mdpData.applianceId,
            MDP: mdpData.ampCap,
            gmt_id: mdpData.gmt,
            switchgear: mdpData.switchgearCap,
            transformer: mdpData.transformer,
            location: mdpData.location
          };
        })
      })
    );

    dispatch(
      sendAddCompany({
        ...newCompany,
        MDP: data.map((mdpData: CreateMDPDataForm) => {
          return {
            siteName: mdpData.name,
            meterID: mdpData.meterId,
            applianceID: mdpData.applianceId,
            MDP: mdpData.ampCap,
            gmt_id: mdpData.gmt,
            switchgear: mdpData.switchgearCap,
            transformer: mdpData.transformer,
            location: mdpData.location
          };
        })
      })
    );
  }

  function previousStepCreateCompany() {
    setStepCreateCompany(stepCreateCompany - 1);
  }

  useEffect(() => {
    if (toastMessage == 'Company created!') {
      setShowModal(false);
      setStepCreateCompany(1);
    }
  }, [toastMessage]);

  return (
    <>
      <div className="px-[30px] py-[10px] w-full">
        <div
          className={
            'justify-center items-center flex mb-[20px] sm:justify-start flex-col-reverse sm:flex-row'
          }>
          <div className={'w-[200px]'}>
            <RoleGuard allowedPermissions={[PERMISSIONS.CREATE_COMPANY]}>
              <ButtonSecondary onClick={() => setShowModal(true)}>
                <span className="flex justify-center items-center">
                  <IconAdd />
                  <span className="f-medium">Add new company</span>
                </span>
              </ButtonSecondary>
            </RoleGuard>
            <ModalMiddle
              show={isShowModal}
              onClose={() => {
                setShowModal(false);
                setStepCreateCompany(1);
              }}>
              {
                stepCreateCompany == 1 ? (
                  <BasicCompanyInformationForm
                    onSubmit={submitBasicCompanyInformationForm}
                  />
                ) : stepCreateCompany == 2 ? (
                  <MainPointContactForm
                    onSubmit={submitMainPointContactForm}
                    onPrevious={previousStepCreateCompany}
                  />
                ) : stepCreateCompany == 3 ? (
                  <SiteManagerForm
                    onSubmit={submitSiteManagerForm}
                    onPrevious={previousStepCreateCompany}
                  />
                ) : stepCreateCompany == 4 ? (
                  <SiteDetailForm
                    defaultData={JSON.parse(siteDefaultDataForm)}
                    onSubmit={submitSiteDetailForm}
                    onPrevious={previousStepCreateCompany}
                  />
                ) : stepCreateCompany == 5 ? (
                  <CreateMDPForm
                    schematicImg={schematicImg}
                    onSubmit={submitCreateMDPForm}
                    onPrevious={previousStepCreateCompany}
                  />
                ) : (
                  <div></div>
                )
              }
            </ModalMiddle>
          </div>
          <span className="ml-[20px]">
            <h3 className="f-bold text-lg">Company Management</h3>
          </span>
        </div>
        <RoleGuard allowedPermissions={[PERMISSIONS.VIEW_COMPANY]}>
          <div className="w-full rounded border-color-secondary border">
            <DataTableCompanies />
          </div>
        </RoleGuard>
      </div>
    </>
  );
}

export default CompanyListPage;
