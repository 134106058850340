import { DataTable } from '@/common/DataTable/v2';
import { TABLE_OPTIONS, TIME_SCALE } from '@/config/enum';
import ElectripureService from '@/service/electripure-service';
import { MDPOverviewRef } from '@/types';
import { getUnixFromTimeScale } from '@/utils';
import moment, { Moment } from 'moment';
import { setDefaultAutoSelectFamilyAttemptTimeout } from 'net';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

type MdpOverviewProps = {
  selectedTimeScale: TIME_SCALE;
};

type MdpOverviewResponseData = {
  avg_pf1: number;
  avg_pf2: number;
  avg_pf3: number;
  avg_curr1: number;
  avg_curr2: number;
  avg_curr3: number;
  avg_volt1: number;
  avg_volt2: number;
  avg_volt3: number;
  avg_thdc1: number;
  avg_thdc2: number;
  avg_thdc3: number;
  avg_thdv1: number;
  avg_thdv2: number;
  avg_thdv3: number;
  max_curr1: number;
  max_curr2: number;
  max_curr3: number;
  max_volt1: number;
  max_volt2: number;
  max_volt3: number;
  min_curr1: number;
  min_curr2: number;
  min_curr3: number;
  min_volt1: number;
  min_volt2: number;
  min_volt3: number;
  sum_kwh: number;
  avg_kwh_daily: number;
  worst_pf_15min_window: number;
};

export const MdpOverview = forwardRef<MDPOverviewRef, MdpOverviewProps>(
  ({ selectedTimeScale }, ref) => {
    const { meterId } = useParams();

    const columnKeys = [' ', 'Total'];
    const [tableData, setTableData] = useState<MdpOverviewResponseData>(
      {} as MdpOverviewResponseData
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formattedData = useMemo(() => {
      const data = [];

      if (tableData) {
        data.push([
          'Average Voltage [V]',
          <>
            <span className="text-gray-400-electripure font-medium">
              {tableData.avg_volt1?.toFixed(1)}
            </span>{' '}
            -{' '}
            <span className="text-red-100-electripure font-medium">
              {tableData.avg_volt2?.toFixed(1)}
            </span>{' '}
            -{' '}
            <span className="text-blue-200-electripure font-medium">
              {tableData.avg_volt3?.toFixed(1)}
            </span>
          </>
        ]);
        data.push([
          'Average Current [A]',
          <>
            <span className="text-gray-400-electripure font-medium">
              {tableData.avg_curr1?.toFixed(1)}
            </span>{' '}
            -{' '}
            <span className="text-red-100-electripure font-medium">
              {tableData.avg_curr2?.toFixed(1)}
            </span>{' '}
            -{' '}
            <span className="text-blue-200-electripure font-medium">
              {tableData.avg_curr3?.toFixed(1)}
            </span>
          </>
        ]);
        data.push([
          'Max Voltage [V]',
          <>
            <span className="text-gray-400-electripure font-medium">
              {tableData.max_volt1?.toFixed(1)}
            </span>{' '}
            -{' '}
            <span className="text-red-100-electripure font-medium">
              {tableData.max_volt2?.toFixed(1)}
            </span>{' '}
            -{' '}
            <span className="text-blue-200-electripure font-medium">
              {tableData.max_volt3?.toFixed(1)}
            </span>
          </>
        ]);
        data.push([
          'Max Current [A]',
          <>
            <span className="text-gray-400-electripure font-medium">
              {tableData.max_curr1?.toFixed(1)}
            </span>{' '}
            -{' '}
            <span className="text-red-100-electripure font-medium">
              {tableData.max_curr2?.toFixed(1)}
            </span>{' '}
            -{' '}
            <span className="text-blue-200-electripure font-medium">
              {tableData.max_curr3?.toFixed(1)}
            </span>
          </>
        ]);

        data.push([
          'Power Factor',
          <>
            <span className="text-gray-400-electripure font-medium">
              {(tableData.avg_pf1 * 100)?.toFixed(1)}%
            </span>{' '}
            -{' '}
            <span className="text-red-100-electripure font-medium">
              {(tableData.avg_pf2 * 100)?.toFixed(1)}%
            </span>{' '}
            -{' '}
            <span className="text-blue-200-electripure font-medium">
              {(tableData.avg_pf3 * 100)?.toFixed(1)}%
            </span>
          </>
        ]);
        data.push([
          'Daily kWh Average',
          <span className="text-gray-400-electripure font-medium">
            {tableData.avg_kwh_daily?.toFixed(0)}
          </span>
        ]);
        data.push([
          'Cumulative kWh',
          <span className="text-gray-400-electripure font-medium">
            {tableData.sum_kwh?.toFixed(0)}
          </span>
        ]);

        data.push([
          'Voltage THD',
          <>
            <span className="text-gray-400-electripure font-medium">
              {tableData.avg_thdv1?.toFixed(2)}%
            </span>{' '}
            -{' '}
            <span className="text-red-100-electripure font-medium">
              {tableData.avg_thdv2?.toFixed(2)}%
            </span>{' '}
            -{' '}
            <span className="text-blue-200-electripure font-medium">
              {tableData.avg_thdv3?.toFixed(2)}%
            </span>
          </>
        ]);

        data.push([
          'Current THD',
          <>
            <span className="text-gray-400-electripure font-medium">
              {tableData.avg_thdc1?.toFixed(2)}%
            </span>{' '}
            -{' '}
            <span className="text-red-100-electripure font-medium">
              {tableData.avg_thdc2?.toFixed(2)}%
            </span>{' '}
            -{' '}
            <span className="text-blue-200-electripure font-medium">
              {tableData.avg_thdc3?.toFixed(2)}%
            </span>
          </>
        ]);
      }

      return data;
    }, [tableData]);

    const doFetch = async (meterId: string, from: Moment, to: Moment) => {
      const { data, message } = await ElectripureService.getMdpOverview(
        meterId,
        {
          to: to.format('YYYY-MM-DD'),
          from: from.format('YYYY-MM-DD')
        }
      );

      if (data === null) toast(message, { type: 'info' });
      setTableData(data);
    };

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = useCallback(
      async (customFrom?: Moment, customTo?: Moment) => {
        if (meterId) {
          setIsLoading(true);
          if (customFrom && customTo) {
            await doFetch(meterId, customFrom, customTo);
          } else {
            const now = moment().utc();
            const from = getUnixFromTimeScale(now.clone(), selectedTimeScale);

            await doFetch(meterId, from, now);
          }
          setIsLoading(false);
        }
      },
      [selectedTimeScale, meterId]
    );

    useImperativeHandle(ref, () => ({ fetchData }));

    return (
      <div className="w-full relative">
        {isLoading ? (
          <BeatLoader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
        ) : (
          <DataTable columns={columnKeys} data={formattedData} />
        )}
      </div>
    );
  }
);
