import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment, { Moment } from 'moment';

type DateTimeProps = { 
  onChange: (name: string, value: Moment) => void;
  value: Moment;
  name: string;
}

export const DateTime = ({ value, name, onChange }: DateTimeProps) => {
  return (
    <DateTimePicker
      renderInput={(props) => <TextField {...props} />}
      value={value}

      onChange={(newValue) => {
        const value = moment(newValue).utc();
        onChange(name, value)
      }}
    />
  );
};
