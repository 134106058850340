import { Fragment } from 'react';
import { IconSort } from '@/assets/svg';
import { HeaderConfig, RowConfig, TableConfig } from './interfaces/datatable';

import './style.css';

type DataTableProps = {
  config: TableConfig;
  onDelete: (data: any) => void;
};

function DataTable({ config, onDelete }: DataTableProps) {
  return (
    <div className="datatable-container">
      <table className="w-full">
        <thead>
          <tr>
            {config.headers.map((header: HeaderConfig, index: number) => {
              return (
                <th
                  className={'p-[10px] datatable-header'}
                  key={'header' + index}>
                  <div className={'flex justify-start align-items'}>
                    {header.label}
                    <span
                      className={
                        header.sort != undefined
                          ? 'cursor-pointer ml-[5px]'
                          : 'hidden'
                      }
                      onClick={header.sort}>
                      <IconSort />
                    </span>
                  </div>
                </th>
              );
            })}
            <th className="text-center">Options</th>
          </tr>
        </thead>
        <tbody>
          {config.data.map((row: RowConfig, indexRow: number) => {
            return (
              <tr key={'row' + indexRow}>
                {config.headers.map((header: HeaderConfig, index: number) => {
                  return (
                    <td
                      className="p-[10px]"
                      key={'row' + indexRow + '-cell' + index}>
                      {row[header.key].label}
                    </td>
                  );
                })}
                <td className="text-center">
                  <span
                    className="text-red-600 font-semibold cursor-pointer"
                    onClick={() => onDelete(row)}>
                    Delete
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default DataTable;
