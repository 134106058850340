import { IconContactCard } from '@/assets/svg';
import { IContact } from '../interfaces';

type ContactInfoProps = {
  contact: IContact;
  className?: string;
};
function ContactInfo({ contact, className }: ContactInfoProps) {
  return (
    <div className={`flex gap-x-4 ${className}`}>
      <div className="flex">
        <IconContactCard />
      </div>
      <div className={`w-full ${className}`}>
        <p>{contact.name}</p>
        <p>{contact.phone}</p>
        <p className="break-words">{contact.email}</p>
      </div>
    </div>
  );
}

export default ContactInfo;
