import { useNavigate } from "react-router-dom";
import { PERMISSIONS } from "../../../../utils/roles";
import { RoleGuard } from "../../../../guards/RoleGuard";

type UserSettingsOptionType = {
    icon: string;
    to: string;
    label: string;
    allowedPermissions: PERMISSIONS[];
};

export const UserSettingsOption = ({ icon, to, label, allowedPermissions}: UserSettingsOptionType) => {
    const navigate = useNavigate();

  return (
    <RoleGuard allowedPermissions={allowedPermissions}>
    <div className="flex hover:bg-slate-100 rounded-lg cursor-pointer">
      <img src={icon} alt="" />
      <p
        className="m-[15px]"
        onClick={() => {
          navigate(to);
        }}>
        {label}
      </p>
    </div>
    </RoleGuard>
  );
};
