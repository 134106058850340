import { MutableRefObject, useEffect, useMemo, useRef } from 'react';
import {
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  ScaleChartOptions
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { ChartLegend } from '../ChartLegend';
import { weekendAreaPlugin } from '../plugins';
import { CustomChartData } from '@/types';
import { DeepPartial } from 'chart.js/types/utils';

type LineChartProps<T, D> = {
  data: CustomChartData<T, D>;
  visiblePoints: number;
  ticks: number;
  legend?: boolean;
  multipleYAxis?: boolean;
  highlightWeekends?: boolean;
  title?: {
    [key: string]: any;
  };
};

ChartJS.register(LinearScale, zoomPlugin);

export const LineChart = <T, D>({
  data,
  visiblePoints,
  ticks,
  title,
  legend,
  multipleYAxis = false,
  highlightWeekends = false
}: LineChartProps<T, D>) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<any>(null);

  const totalPoints = data.labels ? data.labels.length : 0;
  const min = Math.max(totalPoints - visiblePoints, 0);
  const max = totalPoints;

  const getYScale = (data: CustomChartData<T, D>) => {
    const scale: DeepPartial<ScaleChartOptions<'line'>['scales']> = {};
    if (multipleYAxis)
      data.datasets.forEach((d) => {
        if (d.yAxisID)
          scale[d.yAxisID] = {
            type: 'linear',
            position: d.position,
            title: d.title,
            ticks: d.ticks,
            grid: d.grid
          };
      });
    else {
      scale.y = {
        beginAtZero: true,
        type: 'linear',
        title: {
          display: title?.display,
          text: title?.text,
          color: 'black'
        }
      };
    }
    return scale;
  };

  const options: ChartOptions = {
    scales: {
      x: {
        alignToPixels: true,
        min,
        max,
        type: 'category',
        ticks: {
          display: true,
          autoSkip: true,
          maxTicksLimit: ticks
        }
      },
      ...getYScale(data)
    },
    plugins: {
      legend: {
        display: false
      },
      ...(highlightWeekends ? { weekendAreaPlugin: {} } : {}),
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
          modifierKey: 'ctrl'
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.4,
            modifierKey: 'shift'
          },
          pinch: {
            enabled: true
          },
          drag: {
            enabled: true
          },
          mode: 'x'
        }
      }
    }
  };

  const chartData = useMemo(
    () => ({
      ...data,
      datasets: data.datasets.map((d) => {
        const { position, point, ...rest } = d;
        return { ...rest, ...point };
      })
    }),
    [data]
  );

  const legends = useMemo(() => {
    return chartData.datasets.reduce((acc, d) => {
      acc.push({ backgroundColor: d.borderColor, label: d.label || '' });
      return acc;
    }, [] as { backgroundColor: string; label: string }[]);
  }, [chartData]);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (ctx) {
      const chart = new ChartJS(ctx, {
        type: 'line',
        data: chartData,
        options,
        plugins: highlightWeekends ? [weekendAreaPlugin] : []
      });

      chartRef.current = chart;
      return () => chart.destroy();
    }
  }, [chartData]);

  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <div>
      <div className="mb-3">
        <div className="flex flex-col">
          <button
            className="bg-blue-100-electripure text-white px-3 py-1 rounded-md self-end outline-none"
            onClick={resetZoom}>
            Reset Zoom
          </button>
          {legend && <ChartLegend legends={legends} />}
        </div>
      </div>
      <canvas ref={canvasRef} />
    </div>
  );
};
