import { HashRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import ConfirmCaptchaPage from '../pages/CreatePasswordStepper/ConfirmCaptchaPage';
import LoginPage from '../pages/LoginPage';
import ConfirmCodePage from '../pages/ConfirmCodePage';
import SelectVerifyMethodPage from '../pages/SelectVerifyMethodPage';
import RequestResetPasswordPage from '../pages/RequestResetPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import ConfirmCodeSentPage from '../pages/ConfirmCodeSentPage';
import UserListPage from '../pages/UserListPage';
import OverviewPage from '../pages/OverviewPage';
import { Fragment, useEffect } from 'react';
import Loading from '../components/Loading';
import Toast from '../components/Toast';
import { IsAuthenticated, IsAuthenticatedLoginToken } from './Auth';
import CreatePasswordPage from '../pages/CreatePasswordStepper/CreatePasswordPage';
import ConfirmEmailPhonePage from '../pages/CreatePasswordStepper/ConfirmEmailPhonePage';
import CreateBackupContactsPage from '../pages/CreatePasswordStepper/CreateBackupContactsPage';
import CompanyListPage from '../pages/CompanyListPage';
import CompanyDetailsPage from '../pages/CompanyDetailPage';
import UserDetails from '../pages/UserDetails';
import DashboardPage from '../pages/DashboardPage';
import PowerPage from '../pages/PowerPage';
import MeterPage from '../pages/MeterPage';
import HarmonicPage from '../pages/HarmonicPage';
import DeviceListPage from '../pages/DeviceListPage';
import DeviceDetails from '../pages/DeviceDetails';
import VoltageCurrentGraph from '../components/Graphs/VoltageCurrentGraph';
import VoltageCurrentPage from '../pages/VoltageCurrent';
import HarmonicGraph from '../components/Graphs/HarmonicGraph';
import PowerGraph from '../components/Graphs/PowerGraph';
import FirebaseNotifications from '../components/FirebaseNotifications';
import { NAVBAR_MODE } from '../config/enum';
import MdpConfigAlertPage from '../pages/MdpConfigAlertPage';
import MeterStatusPage from '../pages/MeterStatusPage';

const AppRouter = () => {
  return (
    <Fragment>
      <HashRouter>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/login/verify/select"
            element={
              <IsAuthenticatedLoginToken>
                <SelectVerifyMethodPage />
              </IsAuthenticatedLoginToken>
            }
          />
          <Route
            path="/login/verify/confirm"
            element={
              <IsAuthenticatedLoginToken>
                <ConfirmCodePage />
              </IsAuthenticatedLoginToken>
            }
          />
          <Route
            path="/confirm/:token/step/1"
            element={<CreatePasswordPage />}
          />
          <Route
            path="/confirm/:token/step/2"
            element={<ConfirmEmailPhonePage />}
          />
          <Route
            path="/confirm/:token/step/3"
            element={<ConfirmCaptchaPage />}
          />
          <Route
            path="/confirm/:token/step/4"
            element={<CreateBackupContactsPage />}
          />
          <Route path="/reset" element={<RequestResetPasswordPage />} />
          <Route path="/reset/sent" element={<ConfirmCodeSentPage />} />
          <Route path="/reset/:token/confirm" element={<ResetPasswordPage />} />
          <Route
            path="/dashboard"
            element={
              <IsAuthenticated>
                <Outlet />
              </IsAuthenticated>
            }>
            <Route path="" element={<DashboardPage mode={NAVBAR_MODE.OVER} />}>
              <Route path="user/list" element={<UserListPage />} />
              <Route path="admin/overview" element={<OverviewPage />} />
              <Route path="user/detail/:userId" element={<UserDetails />} />
              <Route path="company/list" element={<CompanyListPage />} />
              <Route path="device/list" element={<DeviceListPage />} />
              <Route path="device/details/:devId" element={<DeviceDetails />} />
              <Route
                path="company/details/:ciaId"
                element={<CompanyDetailsPage />}
              />
              <Route path="company/:ciaId" element={<CompanyDetailsPage />} />
            </Route>
            <Route path="" element={<DashboardPage mode={NAVBAR_MODE.OVER} />}>
              <Route path="company/:companyId" element={<Outlet />}>
                {/* <Route
                  path="mdp/:mdpId/alert/config"
                  element={<MdpConfigAlertPage />}
                /> */}
                <Route path="meter/:meterId" element={<MeterPage />}>
                  <Route path="voltagecurrent" element={<VoltageCurrentPage />}>
                    <Route path="*" element={<VoltageCurrentGraph />} />
                  </Route>
                  <Route path="power" element={<PowerPage />}>
                    <Route path="*" element={<PowerGraph />} />
                  </Route>
                  <Route path="harmonic" element={<HarmonicPage />}>
                    <Route path="*" element={<HarmonicGraph />} />
                  </Route>
                  <Route
                    path="system-information"
                    element={<MeterStatusPage />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </HashRouter>
      <Toast />
      <Loading />
    </Fragment>
  );
};

export default AppRouter;
