import React from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateUserDataForm } from '../interfaces/form';
import { ModalMiddle } from './../components/Modal';
import CreateUserForm from '../components/Form/CreateUserForm';
import DataTableUsers from '../components/DataTables/DataTableUsers';
import { ButtonSecondary } from '../components/FormInput/Button';
import { sendCreateUser} from '../actions/electripure';
import { RoleGuard } from '@/guards/RoleGuard';
import { PERMISSIONS } from '@/utils/roles';
import { IconAdd } from '@/assets/svg';

function UserListPage() {
  const [isShowModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  function submitCreateUserForm(data: CreateUserDataForm) {
    dispatch(sendCreateUser(data));
    setShowModal(false);
  }

  return (
    <React.Fragment>
      <div className="justify-center items-center flex mb-[20px] sm:justify-start flex-col-reverse sm:flex-row">
        <div className="w-[172px]">
          <RoleGuard allowedPermissions={[PERMISSIONS.VIEW_USER]}>
            <ButtonSecondary
              onClick={() => {
                setShowModal(true);
              }}>
              <span className="flex justify-center items-center">
                <IconAdd />

                <span className="f-medium">Add new user</span>
              </span>
            </ButtonSecondary>
          </RoleGuard>
        </div>
        <span className="ml-[20px]">
          <h3 className="f-bold text-lg">User Management</h3>
        </span>
      </div>
      <RoleGuard allowedPermissions={[PERMISSIONS.VIEW_USER]}>
        <div className="w-full rounded border-color-secondary border">
          <DataTableUsers />
        </div>
      </RoleGuard>
      <ModalMiddle
        show={isShowModal}
        onClose={() => {
          setShowModal(false);
        }}>
        <CreateUserForm onSubmit={submitCreateUserForm} />
      </ModalMiddle>
    </React.Fragment>
  );
}

export default UserListPage;
