import {
  AddContactRequest,
  AuthorizationCodeRequest,
  AuthorizationCodeValidateRequest,
  CreateUserRequest,
  ForgotPasswordRequest,
  GetAmpsDataRequest,
  GetCompaniesByUserRequest,
  GetPowerDataRequest,
  GetVoltsDataRequest,
  LoginRequest,
  ResendEmailRequest,
  ToogleCompanyStateRequest,
  ToogleUserStateRequest,
  UpdatePasswordRequest,
  UpdateUserRequest,
  UploadImageRequest,
  ValidateTokenRequest,
  ValidateUpdateUserRequest,
  UploadFileRequest,
  ListFilesCompanyRequest,
  GetChartData,
  SetFCMTokenRequest
} from '../interfaces/electripure-service';
import { BaseService } from './base-service';
import environment from './../config/env';
import { toast } from 'react-toastify';
import { ResponseGeneric } from '../interfaces/base-service';
import moment from 'moment';

export default class ElectripureService extends BaseService {
  static async login(payload: LoginRequest): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/login`;
    const response = await this.requestPost(url, payload);
    return response;
  }

  static async validateToken(
    payload: ValidateTokenRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/validate_token?${this.jsonToQueryParams(payload)}`;
    const response = await this.requestPost(url, payload);
    return response;
  }

  static async authorizationCode(
    payload: AuthorizationCodeRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/autherization_code?${this.jsonToQueryParams(payload)}`;
    const response = await this.requestPost(url, payload);
    return response;
  }

  static async authorizationCodeValidate(
    payload: AuthorizationCodeValidateRequest
  ): Promise<ResponseGeneric> {
    // make the changes here
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/autherization_code_validate?${this.jsonToQueryParams(payload)}`;
    const response = await this.requestPost(url, payload);
    return response;
  }

  static async updateUser(
    payload: UpdateUserRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/update_user?${this.jsonToQueryParams(payload)}`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async validateUpdateUser(
    payload: ValidateUpdateUserRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/validate_update_user?${this.jsonToQueryParams(payload)}`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async addContact(
    payload: AddContactRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/add_contact?${this.jsonToQueryParams(payload)}`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async createCompany(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/create_company`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async archiveCompany(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/archive_company`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getUsers(): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_users`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, {}, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getOverview(): Promise<any> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/overview`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const response = await fetch(url, {
      method: 'GET',
      headers
    });

    if (!response.ok) {
      const error = await response.text();

      throw new Error(error || 'Failed to fetch');
    }

    const json = await response.json();
    return json;
  }

  static async resendEmail(
    payload: ResendEmailRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/resend_email?${this.jsonToQueryParams(payload)}`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }
  static async toogleUserState(
    payload: ToogleUserStateRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/change_status_user`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async toogleCompanyState(
    payload: ToogleCompanyStateRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/change_status_company`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async forgotPassword(
    payload: ForgotPasswordRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/forgot_password?${this.jsonToQueryParams(payload)}`;
    const response = await this.requestPost(url, payload);
    return response;
  }

  static async updatePassword(
    payload: UpdatePasswordRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/update_pwd?${this.jsonToQueryParams(payload)}`;
    const response = await this.requestPost(url, payload);
    return response;
  }

  static async createUser(
    payload: CreateUserRequest
  ): Promise<ResponseGeneric> {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/create_user?${this.jsonToQueryParams(payload)}`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    // TODO this is a temporal fix related to the new roles/permissions refactored backend
    let fixed_payload = {
      fullname: payload.fullname,
      email: payload.email,
      cellphone: payload.cellphone,
      role_name: payload.role,
      companies: payload.company ? [parseInt(payload.company)] : [],
      sites: [],
      mdps: []
    }
    const response = await this.requestPost(url, fixed_payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getCompanies(): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_companies`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, {}, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getCompaniesTable(): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_companies_table`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, {}, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getCompaniesByUser(
    payload: GetCompaniesByUserRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_companies_by_id`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async uploadImage(
    payload: UploadImageRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/update_image`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_A`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getDataGraph(
    url: string,
    payload: GetChartData
  ): Promise<ResponseGeneric> {
    const response = await this.requestPost(url, payload, {});
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataAGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_A(A)_v2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataBGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_B(A)_v2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataCGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_C(A)_v2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getAmpsDataNGraph(
    payload: GetAmpsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_N(A)_v2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataAGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_A(V)_v2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataBGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_B(V)_v2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataCGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_C(V)_v2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataGGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_G(V)_v2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getVoltsDataGraph(
    payload: GetVoltsDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart1_V`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerActiveDataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_Active`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerFactorDataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_Factor`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateCompany(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/edit_company`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerApparentDataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_Apparent`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerReactiveDataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_Reactive`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async companyDetail(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/company_detail`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerLine1DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_V1`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateSite(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/site/${payload.site_id}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json'
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify(payload)
    });

    return response.json();
  }

  static async listGMTs(): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_timezone_list`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, {}, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerLine2DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_V2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateMDP(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/mdp/${payload.id}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json'
    };
    const request = await fetch(url, {
      body: JSON.stringify({ ...payload }),
      method: 'PUT',
      headers: headers
    });

    const response = await request.json();
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getPowerLine3DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart3_V3`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateUserDetails(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/edit_user`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicAmpsLine1DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_A1`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicAmpsLine2DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_A2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicAmpsLine3DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_A3`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicVoltLine1DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_V1`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicVoltLine2DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_V2`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getHarmonicVoltLine3DataGraph(
    payload: GetPowerDataRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_chart2_V3`;
    const response = await this.requestPost(url, payload);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async createMDP(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/mdp`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async createSite(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/site`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }
  static async getDevicesTable(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_device_list`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async updateDeviceDetails(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/update_device`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async uploadFile(
    payload: UploadFileRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/upload_file`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async uploadFileData(payload: any): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/register_file_company`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getListFilesCompany(
    payload: ListFilesCompanyRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_list_files_company`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async saveFCMToken(
    payload: SetFCMTokenRequest
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/upload_fcmtoken`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, payload, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async getNotifications(): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_alert_history`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(url, {}, header_auth);
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }
  static async getConfigByMdp(mdpId: number): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/get_json_mdp`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(
      url,
      {
        mdp_id: mdpId
      },
      header_auth
    );
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async deleteConfigByMdpAlert(
    alertId: number
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/delete_alert`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(
      url,
      {
        id: alertId
      },
      header_auth
    );
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async deleteConfigByMdpEvent(
    eventId: number
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/delete_event`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    const response = await this.requestPost(
      url,
      {
        id: eventId
      },
      header_auth
    );
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async setConfigByMdp(
    mdpId: number,
    data: any
  ): Promise<ResponseGeneric> {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/upload_json_mdp`;
    const header_auth = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
    };
    console.log('data : ', data);
    const response = await this.requestPost(
      url,
      {
        mdp_id: mdpId,
        mdp_json: data
      },
      header_auth
    );
    if (!response.success) {
      toast.error(response.error, {
        position: 'bottom-right'
      });
    }
    return response;
  }

  static async deleteMdp(id: number) {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/mdp/${id}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const response = await fetch(url, {
      method: 'DELETE',
      headers
    });

    if (!response.ok) throw Error();

    const json = await response.json();
    return json;
  }

  static async deleteSite(id: number) {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/site/${id}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const response = await fetch(url, {
      method: 'DELETE',
      headers
    });

    if (!response.ok) throw Error();

    const json = await response.json();
    return json;
  }

  static async deleteUser(id: number) {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/user/${id}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const response = await fetch(url, {
      method: 'DELETE',
      headers
    });

    if (!response.ok) throw Error();

    const json = await response.json();
    return json;
  }

  static async getMdpValues(
    ids: number[],
    from: moment.Moment,
    to: moment.Moment
  ) {
    const url = `${
      environment.ELECTRIPURE_ENDPOINT
    }/mdp/values?from=${from.unix()}&to=${to.unix()}`;
    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        mdpIds: ids
      })
    });

    const json = await response.json();

    return json;
  }

  static async deleteFile(fileId: number) {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/file/${fileId}`;

    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const response = await fetch(url, {
      method: 'DELETE',
      headers
    });

    return await response.json();
  }

  static async setSiteAsDefault(siteId: number) {
    const url = `${environment.ELECTRIPURE_ENDPOINT}/site/${siteId}/default`;

    const headers = {
      Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    const response = await fetch(url, {
      method: 'POST',
      headers
    });

    return await response.json();
  }

  static async getMdpSystemInfo(mdpId: string) {
    try {
      const url = `${environment.ELECTRIPURE_ENDPOINT}/mdp/${mdpId}/system_info`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
        }
      });

      return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  static async getMdpOverview(
    mdpId: string,
    query: { to: string; from: string }
  ) {
    try {
      const url = `${environment.ELECTRIPURE_ENDPOINT}/mdp/${mdpId}/overview?date_from=${query.from}&date_to=${query.to}`;
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
      };

      const response = await fetch(url, {
        method: 'GET',
        headers
      });

      return await response.json();
    } catch (error) {
      console.log(error);
    }
  }

  static async getOverviewChartMdp(mdpId: string, query: { to: string, from: string}) {
    try {
      const url = `${environment.ELECTRIPURE_ENDPOINT}/mdp/${mdpId}/overview-plot?date_from=${query.from}&date_to=${query.to}`;
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('electripureJwt')}`
      };

      const response = await fetch(url, {
        method: 'GET',
        headers
      });

      return await response.json();
    } catch (error) {
      console.log(error);
    }
  }
}
