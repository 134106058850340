import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { ElectripureState } from '../../interfaces/reducers';
import { PERMISSIONS } from '../../utils/roles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

type RoleGuardProps = {
  fallback?: ReactNode;
  allowedPermissions: PERMISSIONS[];
  children: ReactNode;
};

export const RoleGuard = ({
  fallback,
  allowedPermissions,
  children
}: RoleGuardProps) => {
  // This is really bad and shouldn't be done this way, but refactor every useSelector that's being used is going to take some time
  const currentUser = JSON.parse(
    useSelector((state: ElectripureState) => state.currentUser || '{}')
  );

  const navigate = useNavigate();

  if (!currentUser) {
    toast('Login expired', {
      type: 'info'
    });
    navigate('/login');
  }

  return allowedPermissions.some((permission) =>
    currentUser.permissions?.includes(permission)
  )
    ? children
    : fallback;
};
