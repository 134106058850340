type MDPInformationProps = {
  name: string;
};

export const MDPInformation = ({ name }: MDPInformationProps) => {
  return (
    <div className="rounded-md border-gray-200-electripure border-2 w-full px-4 py-2 bg-white">
      <p className="text-xl font-medium mb-2">MDP Information</p>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">MDP Name</span>
        <span className="w-full font-bold text-black">{name}</span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">MDP Rating</span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">Breaking Rating</span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">Transformer Serial</span>
      </div>
      <div className="flex justify-between text-gray-400-electripure">
        <span className="w-full">Transformer Rating</span>
      </div>
    </div>
  );
};
