export enum PERMISSIONS {
  EDIT_COMPANY = 'edit_company',
  CREATE_COMPANY = 'create_company',
  VIEW_COMPANY = 'view_company',
  DELETE_COMPANY = 'delete_company',
  VIEW_SITE = 'view_site',
  CREATE_SITE = 'create_site',
  EDIT_SITE = 'edit_site',
  DELETE_SITE = 'delete_site',
  VIEW_MDP = 'view_mdp',
  CREATE_MDP = 'create_mdp',
  EDIT_MDP = 'edit_mdp',
  DELETE_MDP = 'delete_mdp',
  VIEW_USER = 'view_user',
  CREATE_USER = 'create_user',
  EDIT_USER = 'edit_user',
  DELETE_USER = 'delete_user'
}
