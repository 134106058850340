import { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalMiddle } from './../components/Modal';
import { sendCreateUser, sendGetOverview } from '../actions/electripure';
import { RoleGuard } from '@/guards/RoleGuard';
import { PERMISSIONS } from '@/utils/roles';
import { ElectripureState } from '@/interfaces/reducers';
import {
  CloudIcon,
  CloudOffIcon,
  FactoryIcon,
  GasMeterIcon,
  IconAdd
} from '@/assets/svg';
import { CreateCompanyForm } from '@/common/Form/CreateCompany';
import { DataTable, Header } from '@/common/DataTable/';
import { Link } from 'react-router-dom';

function UserListPage() {
  const [modal, setModal] = useState<{ company: boolean; device: boolean }>({
    company: false,
    device: false
  });

  const dispatch = useDispatch();
  const overview = useSelector((state: ElectripureState) => state.overview);

  const totalsKeys = {
    customers: {
      text: 'Total Customers',
      icon: <FactoryIcon />
    },
    deployed_meters: {
      text: 'Total Deployed Meters',
      icon: <GasMeterIcon />
    },
    meters_online: {
      text: 'Meters Online',
      icon: <CloudIcon />
    },
    meters_offline: {
      text: 'Meters Offline',
      icon: <CloudOffIcon />
    }
  } as const;

  useEffect(() => {
    dispatch(sendGetOverview({}));
  }, []);

  const tableColumns: Header[] = [
    {
      key: 'meter_id',
      label: <span>Meter ID</span>,
      sorted: true
    },
    {
      key: 'voltage_status',
      label: <span className="w-16 text-xs">Voltage & Current</span>
    },
    {
      key: 'harmonics_status',
      label: <span className="w-16 text-xs">Harmonics</span>
    },
    {
      key: 'company_name',
      label: <span>Company</span>,
      sorted: true
    },
    {
      key: 'mdp_name',
      label: <span>MDP Name</span>,
      sorted: true
    },
    {
      key: 'meter_type',
      label: <span>Meter Type</span>
    },
    {
      key: 'message',
      label: <span>Message</span>
    }
  ];

  const tableData = useMemo(() => {
    if (!overview) {
      return [];
    }
    return overview.overview_table.map((data) => ({
      voltage_status: (
        <div
          className="h-3 w-3 mx-auto rounded-full"
          style={{ backgroundColor: data.voltage_status }}
        />
      ),
      harmonics_status: (
        <div
          className="h-3 w-3 mx-auto rounded-full"
          style={{ backgroundColor: data.harmonics_status }}
        />
      ),
      meter_id: (
        <Link
          to={`/dashboard/device/details/${data.meter_id}`}
          className="text-blue-100-electripure">
          {data.meter_id}
        </Link>
      ),
      mdp_name: (
        <Link
          to={`/dashboard/company/${data.company_id}/meter/${data.meter_id}/system-information`}
          className="text-blue-100-electripure">
          {data.mdp_name}
        </Link>
      ),
      company_name: (
        <Link
          to={`/dashboard/company/${data.company_id}`}
          className="text-blue-100-electripure">
          {data.company_name}
        </Link>
      ),
      meter_type: data.meter_type,
      message: (
        <div>
          <p>Last point for voltage {data.voltage_last}</p>
          <p>Last point for harmonics {data.harmonics_last}</p>
        </div>
      )
    }));
  }, [overview]);

  return (
    <>
      <div
        className={
          'justify-center items-center flex mb-[20px] sm:justify-start flex-col-reverse sm:flex-row'
        }>
        <span className="ml-[20px]">
          <h3 className="f-bold text-lg">OverviewPage</h3>
        </span>
      </div>
      <RoleGuard allowedPermissions={[PERMISSIONS.VIEW_COMPANY]}>
        <div className="flex flex-col gap-y-6">
          <div className="flex justify-between">
            <div className="inline-flex flex-col flex-wrap max-h-[130px] max-w-max bg-white rounded border-[1px] border-gray-200-electripure gap-x-6 gap-y-4 px-6 py-6">
              {Object.keys(totalsKeys).map((key, index) => {
                const typedKey = key as keyof typeof totalsKeys;

                return (
                  <div className="flex grow" key={index}>
                    {totalsKeys[typedKey].icon}{' '}
                    <span className="grow">{totalsKeys[typedKey].text}</span>
                    <span className="ml-4">
                      {overview && overview[typedKey]}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col justify-between">
              <button
                className="border-[1px] border-gray-200-electripure px-2 py-3 rounded-sm bg-white"
                onClick={() =>
                  setModal((prev) => ({ ...prev, company: true }))
                }>
                <span className="flex justify-center items-center">
                  <IconAdd />
                  <span className="f-medium text-gray-300-electripure">
                    Add new company
                  </span>
                </span>
              </button>
              <button
                className="border-[1px] border-gray-200-electripure px-2 py-3 rounded-sm bg-white"
                onClick={() => setModal((prev) => ({ ...prev, device: true }))}>
                <span className="flex justify-center items-center">
                  <IconAdd />
                  <span className="f-medium text-gray-300-electripure">
                    Add new device
                  </span>
                </span>
              </button>
            </div>
          </div>
          <div className="w-full bg-white px-4 py-8 border-[1px] border-gray-200-electripure rounded-md">
            <DataTable headers={tableColumns} data={tableData} />
          </div>
        </div>
      </RoleGuard>
      {/* <ModalMiddle
        show={modal.company}
        onClose={() => setModal((prev) => ({ ...prev, company: false }))}>
        <CreateCompanyForm />
      </ModalMiddle> */}
    </>
  );
}

export default UserListPage;
