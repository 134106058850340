export default {
  //"ELECTRIPURE_ENDPOINT": "https://flaskapi.store",//"http://flaskapi-env.eba-swfkr2ub.us-east-1.elasticbeanstalk.com"
  ELECTRIPURE_ENDPOINT: 'https://apidev.electripuremeter.com',
  // ELECTRIPURE_ENDPOINT: 'https://apistaging.electripuremeter.com',
  // ELECTRIPURE_ENDPOINT: 'http://127.0.0.1:5000',
  VAPID_KEY:
    'BN2CaREzbO05UibxwJjmeXz6HKDdHsjKbh2oBW6fLbr5q00_TlHBZm7JaTF0YKtoQdmSV7qRo2V338qUMTeWVpQ',
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyDRx7HiK9jki6_WNKpR2DiflthdnLNuVrE',
    authDomain: 'electripure-498d1.firebaseapp.com',
    projectId: 'electripure-498d1',
    storageBucket: 'electripure-498d1.appspot.com',
    messagingSenderId: '179416245805',
    appId: '1:179416245805:web:37e35d078736ad16563aa4'
  },
  RATE_SCHEDULE: [
    {
      id: 1,
      value: '6'
    },
    {
      id: 2,
      value: '6A'
    },
    {
      id: 3,
      value: '6B'
    },
    {
      id: 4,
      value: '8'
    },
    {
      id: 5,
      value: '9'
    }
  ],
  STATES: [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ]
};
