import { ReactNode } from 'react';

type ButtonProps = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};
export const Button = ({ children, className, onClick }: ButtonProps) => {
  return <button className={`outline-none bg-gray-200-electripure text-gray-300-electripure ${className}`} onClick={onClick}>{children}</button>;
};
