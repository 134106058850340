import { Fragment, useEffect, useState } from 'react';
import { ButtonNotification } from '../../../FormInput/Button';
import useOnclickOutside from 'react-cool-onclickoutside';
import UserSettings from './UserSettings';
import { useLocation } from 'react-router-dom';

function FloatUserSetting({ children }: { children: any }) {
  const location = useLocation();

  const [toggleSettings, setToggleSettings] = useState(false);
  const handleBtnClick = (): void => setToggleSettings(!toggleSettings);
  const closeMenu = (): void => setToggleSettings(false);
  const ref = useOnclickOutside(() => closeMenu());

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <>
      <div ref={ref}>
        <ButtonNotification onClick={handleBtnClick}>
          {children}
        </ButtonNotification>
        {toggleSettings && (
          <div className="absolute shadow-md md:top-[110px] lg:top-[70px] bg-white w-[300px] py-[20px] px-[10px] right-[30px] rounded-lg z-10">
            <UserSettings />
          </div>
        )}
      </div>
    </>
  );
}

export default FloatUserSetting;
