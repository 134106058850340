import {
  deleteFile,
  sendGetUploadedFiles,
  sendUploadFileData
} from '@/actions/electripure';
import { IconTrash } from '@/assets/svg';
import UploadFileForm from '@/components/Form/UploadFileForm';
import { ModalMiddle } from '@/components/Modal';
import { UploadFileDataForm } from '@/interfaces/form';
import { ElectripureState } from '@/interfaces/reducers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const UploadedFiles = () => {
  const [showUploadFileForm, setShowUploadFileForm] = useState<boolean>(false);
  const [rerenderCount, setRerenderCount] = useState<number>(0);
  const currentCompany = JSON.parse(
    useSelector((state: ElectripureState) => state.companyDetails)
  );
  const uploadedFiles = JSON.parse(
    useSelector((state: ElectripureState) => state.uploadedFiles)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (currentCompany)
        dispatch(sendGetUploadedFiles({ companyId: currentCompany.id }));
    };

    fetchData();
  }, [currentCompany]);

  const submitFileInfo = (data: UploadFileDataForm) => {
    setShowUploadFileForm(false);
    dispatch(sendUploadFileData(data));
  };

  const deleteUploadFile = async (fileId: number) => {
    await dispatch(deleteFile(currentCompany.id, fileId));
    dispatch(sendGetUploadedFiles({ companyId: currentCompany.id }));
  };
  return (
    <>
      <div className="self-start justify-self-end flex flex-col gap-y-4 w-full grow">
        <div className="flex items-center gap-x-0">
          <p className="">Site Files</p>
          {/* <button
            className="w-[200px] border h-[50px] ml-[10px]"
            onClick={() => setShowUploadFileForm(true)}>
            Add Files
          </button> */}
        </div>
        {uploadedFiles.length > 0 ? (
          <table className="w-full">
            <tbody>
              {uploadedFiles.map((f) => (
                <tr className="w-full odd:bg-gray-100-electripure">
                  <td
                    className="border-none text-blue-100-electripure font-medium"
                    key={f.id}>
                    {f.fileName}
                  </td>
                  <td
                    className="border-none"
                    onClick={() => deleteUploadFile(f.id)}>
                    <IconTrash />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-gray-300-electripure">No files to show</p>
        )}
      </div>
      {/* <ModalMiddle
        show={showUploadFileForm}
        onClose={() => {
          setShowUploadFileForm(false);
        }}>
        <UploadFileForm onSubmit={submitFileInfo} />
      </ModalMiddle> */}
    </>
  );
};
