import { MouseEventHandler } from 'react';
import { IconCross } from '@/assets/svg';

export function ModalMiddle({
  show,
  children,
  onClose
}: {
  show: boolean;
  children: any;
  onClose: MouseEventHandler;
}) {
  return (
    <>
      {show && (
        <div
          className={`w-full h-full fixed  top-0 left-0 flex justify-center items-center bg-color-black-opacity z-40  p-[10px]`}>
          <div className="max-w-[800px] w-[99%] sm:w-[90%] md:w-[70%] lg:w-[50%] p-[30px] h-auto bg-color-white rounded relative overflow-y-auto max-h-full">
            {children}
            <span
              className="absolute top-[10px] right-[10px] cursor-pointer"
              onClick={onClose}>
              <IconCross />
            </span>
          </div>
        </div>
      )}
    </>
  );
}
