import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ElectripureState } from '@/interfaces/reducers';
import { useParams } from 'react-router';
import { ModalMiddle } from '@/components/Modal';
import { UpdateDeviceDataForm } from '../interfaces/form';
import { DeviceData } from '@/interfaces/entities';
import DeviceUpdateForm from '@/components/Form/DeviceUpdateForm';
import { useNavigate } from 'react-router';
import { sendUpdateDeviceDetails } from '@/actions/electripure';
import { IconBattery, IconLeftArrow } from '@/assets/svg';
import { RoleGuard } from '@/guards/RoleGuard';
import { PERMISSIONS } from '@/utils/roles';

function DeviceDetails() {
  const { devId } = useParams();
  const [toggleModal, setToggleModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let devices = JSON.parse(
    useSelector((state: ElectripureState) => state.devicesTable)
  );

  let device: DeviceData = devices.device_list?.find(
    (element: DeviceData) => element.id_device === parseInt(devId ?? '')
  );

  const submitDeviceUpdateInfo = (data: UpdateDeviceDataForm) => {
    dispatch(sendUpdateDeviceDetails(data));
    setToggleModal(false);
  };

  return (
    <>
      <div className="rounded border w-[120px] h-[50px] bg-white mb-[20px] flex items-center justify-evenly">
        <IconLeftArrow />
        <span
          className="text-xl cursor-pointer"
          onClick={() => {
            navigate(-1);
          }}>
          Back
        </span>
      </div>
      <div className="flex bg-white p-[20px] h-[600px] flex-col">
        <div className="flex">
          <div className="rounded-[50%] border w-[100px] h-[100px] flex justify-around items-center font-bold text-4xl">
            <h1>{device?.type_device[0]}</h1>
          </div>
          <div className="ml-[100px]">
            <div className="flex">
              <h1 className="text-3xl">{device?.serial_number}</h1>
              <RoleGuard allowedPermissions={[PERMISSIONS.EDIT_MDP]}>
                <span
                  className="cursor-pointer mt-[20px] ml-[20px] text-[#00AEE8]"
                  onClick={() => setToggleModal(!toggleModal)}>
                  Edit Device Information
                </span>
              </RoleGuard>
            </div>

            <div className="mt-[10px]">
              <p className="flex items-center"></p>
            </div>
            <div className="mt-[10px]">
              <p>Type: {device?.type_device}</p>
            </div>
          </div>
        </div>
        <div className="flex w-[100%] gap-[40px]">
          <div className="w-[50%]">
            <div className="flex w-[100%] mt-[25px] text-2xl items-center">
              <h1 className="w-[50%]">Paired device</h1>
              <hr className="w-[50%]" />
            </div>
            <div className="mt-[25px] flex flex-col overflow-scroll border h-[250px] rounded p-[30px]">
              <h1 className="text-3xl mb-[20px] text-[#00AEE8]">
                {device?.serial_number}
              </h1>
              <span className="flex items-center">
                <IconBattery />
                {device?.type_device}
              </span>
            </div>
          </div>
          <div className="w-[50%]">
            <div className="flex w-[100%] mt-[25px] text-2xl items-center">
              <h1 className="w-[40%]">Location details</h1>
              <hr className="w-[60%]" />
            </div>
            <div className="mt-[25px] flex flex-col overflow-scroll border h-[250px] rounded p-[30px]">
              <h1 className="text-3xl mb-[20px] text-[#00AEE8]">
                {device?.company_name}
              </h1>
              <p className="text-xl">{device?.MDP_name}</p>
            </div>
          </div>
        </div>
      </div>
      <ModalMiddle
        show={toggleModal}
        onClose={() => {
          setToggleModal(false);
        }}>
        <DeviceUpdateForm onSubmit={submitDeviceUpdateInfo} device={device} />
      </ModalMiddle>
    </>
  );
}

export default DeviceDetails;
