import { ElectripureState } from '@/interfaces/reducers';
import { useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router';

function HarmonicPage() {
  const { meterId } = useParams();

  const companies = JSON.parse(
    useSelector((state: ElectripureState) => state.companies)
  );

  const companyObject = companies.find((company) => {
    const result = company.list_sites.find((site) =>
      site.mdp_list.find((mdp) => mdp.meter_id == meterId)
    );

    return result;
  });

  return (
    <>
      <div className="h-[30px] flex items-center gap-x-2">
        <h3 className="f-bold text-lg text-blue-600">Harmonics</h3>
        <span>
          {companyObject.company_name},{' '}
          {companyObject.list_sites[0].mdp_list[0].name}
        </span>
      </div>
      <div
        style={{ height: 'calc(100% - 30px)' }}
        className="w-full rounded border-color-secondary border bg-white p-[30px]">
        <Outlet />
      </div>
    </>
  );
}

export default HarmonicPage;
