import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { sendGetCompanyDetail } from '../../actions/electripure';
import DataTableUploadFiles from '../../components/DataTables/DataTableUploadFiles';
import CompanyDetails from './CompanyDetail';
import Sites from './Sites';

const CompanyDetailsPage = () => {
  const dispatch = useDispatch();
  let { ciaId } = useParams();
  let companyId: number = parseInt(ciaId!);

  useEffect(() => {
    dispatch(sendGetCompanyDetail({ cia_id: companyId }));
  }, [ciaId]);

  return (
    <div className="flex flex-col gap-y-8">
      <CompanyDetails />
      <Sites />
      {/* <DataTableUploadFiles companyId={companyId} /> */}
    </div>
  );
};

export default CompanyDetailsPage;
