import {
  SET_LOADING,
  SET_LOGIN_TOKEN,
  SET_TIMESTAMP_TWO_STEP_VERIFICATION,
  SHOW_TOAST,
  SET_JWT,
  SET_USERS,
  SET_PASSWORD_TOKEN,
  SET_PASSWORD_USER,
  SET_COMPANIES,
  ADD_TASK,
  SET_GLOBAL_COMPANIES,
  SET_CURRENT_USER,
  SET_COMPANIES_TABLE,
  SET_VOLTS_DATA,
  SET_AMPS_DATA,
  FILTER_VOLTS_DATA,
  FILTER_AMPS_DATA,
  SET_COMPANY_DETAIL,
  SET_DEVICES_TABLE,
  SET_UPLOADED_FILES,
  SET_FCMTOKEN,
  SET_REMEMBERTOKEN,
  GET_NOTIFICATIONS,
  CHANGE_VIEW_MODE,
  DELETE_SITE_FILE,
  SET_OVERVIEW
} from '../actions/types';
import {
  ActionNotification,
  SetJwtPayload,
  SetLoadingPayload,
  SetLoginTokenPayload,
  SetPasswordTokenPayload,
  SetPasswordUserPayload,
  SetTimestampTwoStepVerificationPayload,
  SetUsersPayload,
  ShowToastPayload,
  SetCompaniesPayload,
  AddTaskPayload,
  SetGlobalCompaniesPayload,
  SetCurrentUserPayload,
  SetCompaniesTablePayload,
  SetAmpsDataPayload,
  SetVoltsDataPayload,
  FilterVoltsDataPayload,
  FilterAmpsDataPayload,
  SetCompanyDetailPayload,
  SetDevicesTablePayload,
  SetUploadedFilePayload,
  SetFCMTokenPayload,
  SetRememberTokenPayload
} from '../interfaces/actions';
import { INotification } from '../interfaces/electripure-service';
import { ElectripureState } from '../interfaces/reducers';

const initialState: ElectripureState = {
  loading: false,
  electripureJwt: null,
  loginToken: null,
  rememberToken: null,
  fcmToken: null,
  toastMessage: '',
  toastTitle: '',
  toastType: '',
  timestampTwoStepVerification: null,
  users: '[]',
  companiesTable: '[]',
  globalCompanies: '[]',
  companies: '[]',
  uploadedFiles: '[]',
  passwordToken: null,
  passwordUser: '{}',
  tasks: '{}',
  currentUser: null,
  ampsData: `{ "timestamp": [] }`,
  ampsDataFiltered: `{ "timestamp": [] }`,
  ampsDataToggle: `{}`,
  voltsData: `{ "timestamp": [] }`,
  voltsDataFiltered: `{ "timestamp": [] }`,
  voltsDataToogle: `{}`,
  companyDetails: `{}`,
  devicesTable: `{}`,
  notifications: [],
  viewMode: 'overview',
  overview: undefined
};

export const electripureReducer = (
  state: ElectripureState = initialState,
  action: ActionNotification
): ElectripureState => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: (action.payload as SetLoadingPayload).loading
      };
      break;
    case SET_FCMTOKEN:
      return {
        ...state,
        fcmToken: (action.payload as SetFCMTokenPayload).token
      };
      break;
    case SET_REMEMBERTOKEN:
      return {
        ...state,
        rememberToken: (action.payload as SetRememberTokenPayload).token
      };
      break;
    case SHOW_TOAST:
      let showToastPayload: ShowToastPayload =
        action.payload as ShowToastPayload;
      return {
        ...state,
        toastMessage: showToastPayload.message,
        toastTitle: showToastPayload.title ?? '',
        toastType: showToastPayload.status
      };
      break;
    case SET_LOGIN_TOKEN:
      let setLoginTokenPayload: SetLoginTokenPayload =
        action.payload as SetLoginTokenPayload;
      return {
        ...state,
        loginToken: setLoginTokenPayload.token
      };
      break;
    case SET_PASSWORD_TOKEN:
      let setPasswordTokenPayload: SetPasswordTokenPayload =
        action.payload as SetPasswordTokenPayload;
      return {
        ...state,
        passwordToken: setPasswordTokenPayload.token
      };
      break;
    case SET_PASSWORD_USER:
      let setPasswordUserPayload: SetPasswordUserPayload =
        action.payload as SetPasswordUserPayload;
      return {
        ...state,
        passwordUser: JSON.stringify(setPasswordUserPayload)
      };
      break;
    case SET_JWT:
      let setJwtPayload: SetJwtPayload = action.payload as SetJwtPayload;
      return {
        ...state,
        electripureJwt: setJwtPayload.token
      };
      break;
    case ADD_TASK:
      const tasks: any = JSON.parse(state.tasks);
      let addTaskPayload: AddTaskPayload = action.payload as AddTaskPayload;
      const taskKey: any = addTaskPayload.key;
      tasks[taskKey] = addTaskPayload;
      return {
        ...state,
        tasks: JSON.stringify(tasks)
      };
      break;
    case SET_TIMESTAMP_TWO_STEP_VERIFICATION:
      let setTimestampTwoStepVerification: SetTimestampTwoStepVerificationPayload =
        action.payload as SetTimestampTwoStepVerificationPayload;
      return {
        ...state,
        timestampTwoStepVerification: setTimestampTwoStepVerification.timestamp
      };
      break;
    case SET_USERS:
      let setUsersPayload: SetUsersPayload = action.payload as SetUsersPayload;
      return {
        ...state,
        users: JSON.stringify(setUsersPayload.users)
      };
      break;
    case SET_COMPANIES_TABLE:
      let setCompaniesTablePayload: SetCompaniesTablePayload =
        action.payload as SetCompaniesTablePayload;
      return {
        ...state,
        companiesTable: JSON.stringify(setCompaniesTablePayload.companies)
      };
      break;
    case SET_COMPANIES:
      let setCompaniesPayload: SetCompaniesPayload =
        action.payload as SetCompaniesPayload;
      return {
        ...state,
        companies: JSON.stringify(setCompaniesPayload.companies)
      };
      break;
    case SET_UPLOADED_FILES:
      let setUploadedFilePayload: SetUploadedFilePayload =
        action.payload as SetUploadedFilePayload;
      return {
        ...state,
        uploadedFiles: JSON.stringify(setUploadedFilePayload.uploadedFiles)
      };
      break;
    case SET_GLOBAL_COMPANIES:
      let setGlobalCompaniesPayload: SetGlobalCompaniesPayload =
        action.payload as SetGlobalCompaniesPayload;
      return {
        ...state,
        globalCompanies: JSON.stringify(setGlobalCompaniesPayload.companies)
      };
      break;
    case SET_CURRENT_USER:
      let setCurrentUserPayload: SetCurrentUserPayload =
        action.payload as SetCurrentUserPayload;
      return {
        ...state,
        currentUser: JSON.stringify({
          id: setCurrentUserPayload.id,
          fullname: setCurrentUserPayload.fullname,
          roles: setCurrentUserPayload.role,
          permissions: setCurrentUserPayload.permissions
        })
      };
      break;
    case SET_AMPS_DATA:
      let setAmpsDataPayload: SetAmpsDataPayload =
        action.payload as SetAmpsDataPayload;
      return {
        ...state,
        ampsData: JSON.stringify(setAmpsDataPayload.data)
        // "ampsDataFiltered": JSON.stringify(setAmpsDataPayload.data)
      };
      break;
    case SET_VOLTS_DATA:
      let setVoltsDataPayload: SetVoltsDataPayload =
        action.payload as SetVoltsDataPayload;
      return {
        ...state,
        voltsData: JSON.stringify(setVoltsDataPayload.data),
        voltsDataFiltered: JSON.stringify(setVoltsDataPayload.data)
      };
      break;
    case FILTER_VOLTS_DATA:
      let filterVoltsDataPayload: FilterVoltsDataPayload =
        action.payload as FilterVoltsDataPayload;
      let voltsData: any = JSON.parse(state.voltsData);
      let voltsDataFiltered: any = {};
      Object.keys(filterVoltsDataPayload).forEach((key: string) => {
        if (key == 'timestamp') {
          voltsDataFiltered[key] = voltsData[key];
        } else if (
          filterVoltsDataPayload[key] &&
          voltsData.hasOwnProperty(key)
        ) {
          voltsDataFiltered[key] = voltsData[key];
        }
      });
      return {
        ...state,
        voltsDataFiltered: JSON.stringify(voltsDataFiltered)
      };
      break;
    case FILTER_AMPS_DATA:
      let filterAmpsDataPayload: FilterAmpsDataPayload =
        action.payload as FilterAmpsDataPayload;
      let ampsData: any = JSON.parse(state.ampsData);
      let ampsDataFiltered: any = {};
      Object.keys(filterAmpsDataPayload).forEach((key: string) => {
        if (filterAmpsDataPayload[key] && ampsData.hasOwnProperty(key)) {
          ampsDataFiltered[key] = ampsData[key];
        }
      });
      ampsDataFiltered['timestamp'] = ampsData['timestamp'];
      return {
        ...state,
        ampsDataToggle: JSON.stringify(filterAmpsDataPayload),
        ampsDataFiltered: JSON.stringify(ampsDataFiltered)
      };
      break;
    case SET_COMPANY_DETAIL:
      let setCompanyDetailPayload: SetCompanyDetailPayload =
        action.payload as SetCompanyDetailPayload;
      return {
        ...state,
        companyDetails: JSON.stringify(setCompanyDetailPayload)
      };
      break;
    case SET_DEVICES_TABLE:
      let setDevicesTablePayload: SetDevicesTablePayload =
        action.payload as SetDevicesTablePayload;
      return {
        ...state,
        devicesTable: JSON.stringify(setDevicesTablePayload)
      };
      break;
    // SECTION Notifications
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications as INotification[]
      };
    case CHANGE_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload
      };
    case SET_OVERVIEW:
      return {
        ...state,
        ...action.payload
      };
    // !SECTION
    default:
      return state;
  }
};
