import { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setJwt,
  setLoginToken,
  setTimestampTwoStepVerification
} from './../../../../actions/electripure';

// images
import companyImg from './../assets/company_management.svg';
import userImg from './../assets/users_management.svg';
import deviceImg from './../assets/device_management.svg';
import chageLogImg from './../assets/change_log.svg';
import settingsImg from './../assets/settings.svg';
import logoutImg from './../assets/logout.svg';
import { useNavigate } from 'react-router-dom';
import { ElectripureState } from '../../../../interfaces/reducers';
import { sendGetUsers } from '../../../../actions/electripure';
import { UserPermission, CiaPermission } from '../../../../routers/Permissions';
import { settingPermissions } from '../../../../libs/permissions';
import { PERMISSIONS } from '../../../../utils/roles';
import { UserSettingsOption } from './UserSettingsOption';

const UserSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const options = [
    {
      to: '/dashboard/company/list',
      label: 'Company Management',
      allowedPermissions: [PERMISSIONS.VIEW_COMPANY],
      icon: companyImg
    },
    {
      to: '/dashboard/user/list',
      label: 'Users Management',
      allowedPermissions: [PERMISSIONS.VIEW_USER],
      icon: userImg
    },
    {
      to: '/dashboard/admin/overview',
      label: 'Admin Overview',
      allowedPermissions: [PERMISSIONS.VIEW_MDP],
      icon: deviceImg
    },
    {
      to: '/dashboard/device/list',
      label: 'Device Management',
      allowedPermissions: [PERMISSIONS.VIEW_MDP],
      icon: deviceImg
    }
  ];

  function logout() {
    dispatch(
      setTimestampTwoStepVerification({
        timestamp: null
      })
    );
    dispatch(
      setLoginToken({
        token: null
      })
    );
    dispatch(
      setJwt({
        token: null
      })
    );
    localStorage.removeItem('electripureJwt');
    localStorage.removeItem('user_id');
    localStorage.removeItem('current_user');
    navigate('/login');
  }

  return (
    <>
      {options.map((option, index) => (
        <UserSettingsOption key={index} {...option} />
      ))}
      <div
        onClick={logout}
        className="flex hover:bg-slate-100 rounded-lg cursor-pointer">
        <img src={logoutImg} alt="" />
        <p className="m-[15px]">Logout</p>
      </div>
    </>
  );
};

export default UserSettings;
