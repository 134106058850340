import { IconLocation } from '@/assets/svg';
import { ILocation } from '../interfaces';

type LocationInfoProps = { 
  location: ILocation;
  className?: string;
}
function LocationInfo({ location, className }: LocationInfoProps) {
  const companyMapsLink = `https://www.google.com/maps/place/${location.address}+${location.city}+${location.state}+${location.zip}`;
  return (
    <div className={`flex gap-x-3 ${className}`}>
      <IconLocation />
      <div>
        <a
          href={companyMapsLink}
          target="_blank"
          className="text-blue-100-electripure">
          {location.address}
        </a>
        <p>{location.address2}</p>
        <p>{location.city}</p>
        <p>{location.state}</p>
        <p>{location.zip}</p>
      </div>
    </div>
  );
}

export default LocationInfo;
