import Chart from 'chart.js';

const weekendsAreaPlugin: Chart.Plugin<'line'> = {
  id: 'weekendsAreaPlugin',
  beforeDatasetsDraw: (chart) => {
    const { ctx, chartArea, scales } = chart;

    ctx.save();
    ctx.fillStyle = '#eeeeee'; // Color para fines de semana

    const labels = (chart.data.labels || []) as string[];
    const xScale = scales.x;

    labels.forEach((label, index) => {
      const date = new Date(label);
      const dayOfWeek = date.getDay(); 

      // Comprobar si está en el rango visible
      if (index >= xScale.min && index < xScale.max) {
        if (dayOfWeek === 5) {
          // Sábado o Domingo
          const startX = xScale.getPixelForValue(index);
          const endX =xScale.getPixelForValue(index + 1) 
          ctx.fillRect(startX, chartArea.top, endX - startX, chartArea.height);
        }
      }
    });

    ctx.restore();
  }
};

export default weekendsAreaPlugin;
