import { INotification } from '../../interfaces/electripure-service';
import NotificationItem from './NotificationItem';

export interface INotificationListProps {
  notifications: INotification[];
  type: string;
}

function NotificationList({ notifications, type }: INotificationListProps) {
  return (
    <div>
      {notifications.map((notification, index) => (
        <NotificationItem key={index} notification={notification} type={type} />
      ))}
    </div>
  );
}

export default NotificationList;
